.types {
    margin: 0 auto;
    padding: 0;
}

@media screen and (min-width: 768px) {
    .types {
        max-width: 1200px;
        padding-bottom: 100px;
    }
}