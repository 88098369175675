.popup__btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    color: #448AFF;
    font-size: 16px;
    line-height: 100%;
    font-weight: 500;

    border: 1px solid #448AFF;
    border-radius: 10px;
}