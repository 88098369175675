.menu {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    padding: 0;
    margin: 0;

    color: #000;
    list-style: none;
}