.service__item-text {
    margin: 0;

    font-size: 13px;
    line-height: 138.5%;
}

@media screen and (min-width: 768px) {
    .service__item-text {
        font-size: 16px;
        line-height: 175%;
    }
}