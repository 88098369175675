.page {
    position: relative;
    z-index: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    min-height: 100vh;
  
    background-color: #F2F5FA;
    ;
    color: #38496C
    ;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 116.7%;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
}
  
@media screen and (min-width: 768px) {
    .page {
        font-size: 16px;
        line-height: calc(28 / 16);
    }
}