.types__icon {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 13px;

    width: 60px;

    text-align: center;
}
@media screen and (min-width: 768px) {
    .types__icon {
       width: 118px;
    }
}