.footer {
    display: flex;
    justify-content: left;
    align-items: flex-end;
    gap: 20px;

    width: 100%;

    margin: 0 auto;

    border-top: 1px solid #C9D6F1;
}

