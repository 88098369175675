.types__block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 40px 0 0;
}

@media screen and (min-width: 768px) {
    .types__block {
        flex-wrap: nowrap;
        padding: 70px 0 0;
    }
}