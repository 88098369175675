.popup__close {
    position: absolute;
    top: 20px;
    right: 14px;

    width: 22px;
    height: 22px;

    padding: 0;

    background-image: url("../../../images/close-popup.svg");
    box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.4);
}