.service__block {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    flex-wrap: wrap;

    max-width: 1122px;

    padding-top: 18px;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .service__block {
        padding-top: 72px;
    }
}