.header__user {
    display: flex;
    align-items: flex-start;
    gap: 14px;

    padding-top: 6px;
}


@media screen and (min-width: 768px) {
    .header__user {
        padding-top: 0;
    }
}