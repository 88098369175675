.btn__menu {
    width: 23px;
    width: 23px;
    height: 16px;

    padding: 0;
    margin: 0 16px 0 0;
    background-image: url('../../../images/menu.svg');
    background-size: 23px 16px;
    background-repeat: no-repeat;
    
    

    border-radius: 0;
}

@media screen and (min-width: 820px) {
    .btn__menu {
        display: none;
    }
}