.types__icons {
    padding: 0 14px;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 15px 46px;
}

@media screen and (min-width: 768px) {
    .types__icons {
        padding: 0;

        gap: 15px 28px;
    }
}