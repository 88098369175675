.service__item-title {
    padding: 0;
    padding-bottom: 12px;
    margin: 0;

    font-size: 16px;
    font-weight: 700;
    line-height: 175%;
    letter-spacing: -0.08px;
}

@media screen and (min-width: 768px) {
    .service__item-title {
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.1px;
    }
}