.footer__block {
    margin: 0 auto;
    padding: 12px 32px 12px;

    width: 100%;
}

@media screen and (min-width: 1270px) {
    .footer__block {
        width: 1200px;
    }
}