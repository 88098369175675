.document {
    flex-grow: 2;

    max-width: 1200px;

    padding: 14px 13px 50px;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .document {
        padding-left: 70px;
        padding-right: 70px;
    }
}

@media screen and (min-width: 1200px) {
    .document {
        padding: 14px 14px 50px;
        box-sizing: border-box;
    }
}