.header__back {
    display: block;

    height: 388px;
    width: 100%;

    margin: 0;
    padding: 0;

    background-image: url('../../../images/header-small.png');
    background-size: auto 380px;
    background-repeat: no-repeat;
    background-position: 36% 0;
}

@media screen and (min-width: 450px) {
    .header__back {
        height: 450px;
        background-size: auto 450px;
        background-position: 30% 0%;
    }
}

@media screen and (min-width: 550px) {
    .header__back {
        height: 450px;
        background-size: auto 450px;
        background-position: 15% 0%;
    }
}

@media screen and (min-width: 768px) {
    .header__back {
        height: 720px;

        background-size: auto 720px;
        background-position: 20% 0%;
    }
}

@media screen and (min-width: 1100px) {
    .header__back {
        width: 100%;
        height: 700px;

        background-size: auto 700px;
        background-image: url('../../../images/header.png');
        background-position: 32% 0%;
    }
}

@media screen and (min-width: 1250px) {
    .header__back {
        width: 100%;
        height: 866px;

        background-size: cover;
        background-image: url('../../../images/header.png');
        background-position: 55% 0%;
    }
}