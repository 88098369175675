.btn {
    padding: 16px 16px;
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    line-height: 123%;
    text-align: center;
    border: none;
    border-radius: 10px;
    background-color: transparent;
}

@media screen and (min-width: 768px) {
    .btn {
        font-size: 16px;
        line-height: 100%;
    }
}