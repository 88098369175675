.service__items {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;

    padding: 0;
    padding-left: 50px;
    margin: 0;
    list-style: none;
}
@media screen and (min-width: 500px) {
    .service__items {
        width: 35%;
    }
}

@media screen and (min-width: 768px) {
    .service__items {
        padding-left: 78px;
    }
}