.btn__google_dark {
    width: 97px;
    height: 28px;
    
    padding: 0;

    background-image: url('../../../images/googleplay-dark.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0;
}


@media screen and (min-width: 768px) {
    .btn__google_dark {
        width: 143px;
        height: px;
    }
}