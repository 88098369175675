.header__text-block {
    width: 58%;

    padding: 17px 19px;
}

@media screen and (min-width: 768px) {
    .header__text-block {
        width: 411px;

        padding: 120px 58px 0 101px;
    }
}