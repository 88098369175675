.block__title {
    margin: 0;
    padding: 0;

    font-size: 18px;
    line-height: 123%;
    font-weight: 900;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .block__title {
        font-size: 42px;
        letter-spacing: 1.68px;
    }
}