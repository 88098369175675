.header {
    max-width: 1200px;
    
    padding: 0 14px 60px;
    margin: 0 auto;

    box-sizing: border-box;
}

@media screen and (min-width: 1228px) {
    .header {
        padding: 0 0 60px;
    }
}