.types__icon-name {
    padding: 0;
    margin: 0;

    font-size: 16px;
    font-weight: 700;
    line-height: 125%;
    text-align: center;
}
@media screen and (min-width: 768px) {
    .types__icon-name {
        font-size: 20px;
        line-height: 140%;
    }
}