.lang__icon-ru {
    display: none;
}

@media screen and (min-width: 768px) {
    .lang__icon-ru {
        display: block;

        width: 32px;
        height: 32px;

        background-image: url('../../../images/russia.svg');
        background-size: cover;
    }
}