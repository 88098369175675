.footer__copiright {
    margin: 0;
    padding: 0;
    font-size: 10px;
    line-height: 260%;
}

@media screen and (min-width: 768px) {
    .footer__copiright {
        font-size: 14px;
        line-height: 186%;
    }
}