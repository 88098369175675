.lang__block {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
}

.lang__block:hover > .lang__popup-list {
    display: flex;

    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .lang__block:hover > .lang__popup-list {
        top: 4px;
        right: -8px;
    }
}