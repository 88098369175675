.types__text {
    margin: 0;
    padding-bottom: 19px;

    font-size: 13px;
    line-height: 138.5%;
}

@media screen and (min-width: 768px) {
    .types__text {
        padding: 0;

        font-size: 16px;
        line-height: 175%;
    }
}