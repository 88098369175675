.menu__item {
    display: none;
}

@media screen and (min-width: 768px) {
    .menu__item {
        display: block;

        padding-top: 14px;
        padding-bottom: 14px;
    }
}