.lang__popup-list {
    display: none;
	position: absolute;
	top: -8px;
    right: -9px;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    

    margin: 0;
    padding: 8px 9px 9px;

    list-style: none;

    background-color: #fff;
    border-radius: 10px;
}