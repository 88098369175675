.service__item_training::before {
    z-index: 1;
    position: absolute;
    top: 0;
    left: -50px;
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    background-image: url('../../../images/training.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (min-width: 768px) {
    .service__item_training::before {
        left: -75px;

        width: 60px;
        height: 60px;
    }
}