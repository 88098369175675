.popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-content: flex-start;
    flex-wrap: wrap;

    width: 100%;
    height: 100vh;

    margin: 0 auto;
    padding: 15px 13px;

    color: #000;
    font-size: 16px;
    background: #F2F5FA;

    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;

    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);    ;
}