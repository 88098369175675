.btn__close {
    width: 20px;
    height: 20px;

    border: none;
    background-image: url(../../../images/close.svg);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center center;

}