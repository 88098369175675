.btn__apple {
    width: 122px;
    height: 36px;

    padding: 0;

    background-image: url('../../../images/appstore.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 122px 35px;
    border-radius: 0;
}

@media screen and (min-width: 768px) {
    .btn__apple {
        height: 52px;
    }
}