.header__back-document {
    display: block;

    margin: 0;
    padding: 0;

    background-image: url('../../../images/doc-back.png');
    background-position: top left;
    background-size: auto 220px;
    background-repeat: no-repeat;
}

@media screen and (min-width: 1000px) {
    .header__back-document {
        width: 100%;
        height: 250px;

        background-size: 100% 330px;
    }
}