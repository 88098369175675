.header__top {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: start;

    padding-top: 14px;
    padding-bottom: 13px;

    box-sizing: border-box;
}

@media screen and (min-width: 1270px) {
    .header__top {
        justify-content: space-between;

        padding-top: 24px;
        padding-bottom: 0;

        width: 1200px;
    }
}