.header__doc-block {
    padding: 8px 0 0;
}

@media screen and (min-width: 768px) {
    .header__doc-block {
        padding-left: 56px;
    }
}

@media screen and (min-width: 1200px) {
    .header__doc-block {
        padding-left: 0;
    }
}