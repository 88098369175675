.types__title {
    padding: 41px 0 18px;
    margin: 0;

    font-size: 18px;
    font-weight: 900;
    line-height: 123.8%;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    text-align: center;
}
@media screen and (min-width: 768px) {
    .types__title {
        padding: 87px 0 41px;
        font-size: 32px;
    }
}