.header__btn-group {
    display: flex;
    justify-content: left;
    gap: 23px;
    flex-wrap: wrap;

    width: 120px;
    height: 36px;

    padding-top: 20px;
}

@media screen and (min-width: 768px) {
    .header__btn-group {
        width: 180px;
        height: 52px;
    }
}